import { TripAdvisorStars } from '@components/TripAdvisorScore';
import { Typography } from '@components/Typography';
import AccessibilityTitles from '@helpers/AccessibilityTitles';
import PrettyTitles from '@helpers/PrettyTitles';
import { Check } from '@untitled-ui/icons-react';
import clsx from 'clsx';
import {
  useInstantSearch,
  useRefinementList,
  UseRefinementListProps,
} from 'react-instantsearch';

export interface RefinementListProps extends UseRefinementListProps {
  label: string;
  showMore?: boolean;
  limit?: number;
  hideLabel?: any[];
  sort?: 'alphabetical' | 'alphabetical-reverse';
  prettifier?: 'titles' | 'accessibility' | 'stars';
  className?: string;
}

export default function RefinementList<RefinementListProps>(props) {
  const { items, refine, canToggleShowMore, isShowingMore, toggleShowMore } =
    useRefinementList(props);

  const {
    label,
    hideLabel,
    sort,
    prettifier = 'titles',
    className,
    enabled,
  } = props;

  const { indexUiState } = useInstantSearch();

  if (
    enabled &&
    !indexUiState?.refinementList?.productCategoryId?.includes(
      enabled.toUpperCase()
    )
  ) {
    return null;
  }

  if (items && sort === 'alphabetical') {
    items.sort((a, b) => a.label.localeCompare(b.label));
  } else if (items && sort === 'alphabetical-reverse') {
    items.sort((a, b) => b.label.localeCompare(a.label));
  }

  return (
    <div className={clsx('shrink-0', className)}>
      {label && items.length > 0 && (
        <label htmlFor={`event-algolia-${label.toLowerCase()}`}>
          <Typography
            tag="p"
            weight="font-semibold"
            size="text-lg"
            className="capitalize">
            {label}
          </Typography>
        </label>
      )}
      {items.length > 0 && (
        <ul
          id={`event-algolia-${label.toLowerCase()}`}
          className="mt-3 space-y-4">
          {items.map((item, index) => {
            if (hideLabel && hideLabel.includes(item.label)) {
              return;
            }
            return (
              <li key={item.label}>
                <label className="flex items-start justify-start">
                  <div className="mr-2 flex size-6 shrink-0 items-center justify-center rounded-sm border border-brand-mothernature bg-white focus-within:border-brand-mothernature focus-within:ring-2 hover:bg-brand-mothernature/10">
                    <input
                      type="checkbox"
                      className="absolute opacity-0"
                      checked={item.isRefined}
                      onChange={(event) => {
                        refine(item.value);
                      }}
                    />
                    <span className={clsx({ hidden: !item.isRefined })}>
                      <Check className="relative size-4" role="presentation" />
                    </span>
                  </div>
                  <div className="flex w-full items-center justify-between space-x-2">
                    <Typography
                      tag="p"
                      size="text-base"
                      className="m-0 select-none capitalize leading-normal">
                      {prettifier === 'titles' &&
                        PrettyTitles(item.label, true)}
                      {prettifier === 'accessibility' &&
                        AccessibilityTitles(item.label)}
                      {prettifier === 'stars' && (
                        <span className="flex items-center pt-1">
                          <TripAdvisorStars score={Number(item.label)} />
                        </span>
                      )}
                    </Typography>
                    <Typography
                      tag="p"
                      size="text-xs"
                      className="text-gray-600">
                      {item.count}
                    </Typography>
                  </div>
                </label>
              </li>
            );
          })}
        </ul>
      )}

      {canToggleShowMore && (
        <button
          onClick={() => toggleShowMore()}
          className="mt-4 rounded-sm focus:border-blue-300 focus:outline-none focus:ring-2">
          <Typography
            tag="p"
            size="text-base"
            className="text-brand-mothernature underline">
            View {isShowingMore ? 'less' : 'more'}
          </Typography>
        </button>
      )}
    </div>
  );
}
