import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import clsx from 'clsx';
import Link from 'next/link';
import { ConditionalWrapper } from './ConditionalWrapper';

const buttonVariants = cva(
  'inline-flex shrink-0 items-center justify-center nice-transition whitespace-nowrap rounded-md text-base font-semibold transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 px-4 ',
  {
    variants: {
      variant: {
        default: 'bg-brand-mothernature text-white hover:bg-brand-whale',
        deal: 'bg-brand-wintersunset text-white hover:bg-brand-wintersunrise',
        outline: 'border hover:bg-gray-700 hover:text-black border',
        secondary:
          'bg-white text-black hover:bg-brand-whale hover:text-white border border-gray-400 hover:border-black',
        ghost: 'hover:bg-gray-700 hover:text-black',
        link: 'text-brand-mothernature underline hover:underline',
      },
      size: {
        default: 'h-11 text-base',
        sm: 'h-9 rounded-md text-sm',
        lg: 'h-12 rounded-md text-lg',
        xl: 'text-base h-12 lg:h-14 rounded-md px-4 lg:px-8 lg:text-xl',
        icon: 'size-10',
      },
      fullWidth: {
        true: 'w-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      fullWidth: false,
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  link?: string;
  newWindow?: boolean;
  title?: string;
  action?: () => void;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      fullWidth,
      asChild = false,
      link,
      newWindow,
      action,
      title,
      ...props
    },
    ref
  ) => {
    const ButtonComponent = asChild ? Slot : 'button';

    return (
      <ConditionalWrapper
        condition={typeof link === 'string'}
        wrapper={(children) => (
          <Link
            href={link}
            target={newWindow ? '_blank' : '_self'}
            title={title}
            className="inline-block"
            itemProp="url">
            {children}
          </Link>
        )}>
        <ButtonComponent
          className={clsx(
            buttonVariants({ variant, size, fullWidth, className })
          )}
          ref={ref}
          onClick={action}
          aria-label={title}
          {...props}
        />
      </ConditionalWrapper>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
