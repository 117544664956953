import { Button } from '@components/Button';
import { ConditionalWrapper } from '@components/ConditionalWrapper';
import Favourite from '@components/Favourite';
import ImageWrapper from '@components/Image';
import TripAdvisorScore from '@components/TripAdvisorScore';
import { Typography } from '@components/Typography';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { EventDateDisplay } from '@helpers/EventDateDisplay';
import PrettyTitles from '@helpers/PrettyTitles';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import { CardProps, DealFlag } from './Card';

export const CardDefault: React.FC<CardProps> = ({
  image,
  heading,
  summary,
  meta,
  favourite,
  buttons,
  link,
  className,
  priority,
}) => {
  const cardTitle =
    meta.type.toUpperCase() === 'PAGE'
      ? ' '
      : PrettyTitles(meta.type?.toString());

  return (
    <div className={clsx('flex h-full flex-col justify-between', className)}>
      <div className="flex flex-col gap-4 pb-4">
        <div className="flex justify-between">
          <Typography
            tag="p"
            size="text-sm"
            weight="font-semibold"
            className="capitalize">
            {meta.type === 'DEAL' ? <DealFlag /> : cardTitle}
          </Typography>
          <div className="flex items-center space-x-2">
            {meta.hasDeal && <DealFlag />}
            {favourite && (
              <Favourite productID={favourite.productId} style="card" />
            )}
          </div>
        </div>
        <ConditionalWrapper
          condition={!!link}
          wrapper={(children) => (
            <Link
              href={link}
              title={heading}
              target={link.includes('http') ? '_blank' : '_self'}>
              {children}
            </Link>
          )}>
          <div className="relative block h-48 flex-none overflow-hidden rounded-lg bg-gray-200">
            <ImageWrapper
              {...image}
              cover
              fullWidth
              fullHeight
              alt={heading}
              dimensions={{ width: 740, height: 480 }}
              imgClassName="group-hover:scale-105 nice-transition"
              priority={priority}
            />
            {(meta.score || meta.event) && (
              <div className="nice-transition absolute inset-0 flex flex-wrap items-end justify-start bg-gradient-to-b from-transparent via-black/20 to-black/60 ease-in">
                <div>
                  {meta.score && (
                    <TripAdvisorScore
                      score={meta.score?.rating}
                      numberReviews={meta.score?.numberReviews}
                      type="inline"
                      className="mb-3 ml-4 text-white"
                    />
                  )}
                  {meta.event && meta.event?.from && meta.event?.to && (
                    <Typography
                      tag="time"
                      weight="font-semibold"
                      className="text-shadow mb-3 ml-4 flex items-center space-x-2 text-sm text-white"
                      dateTime={meta.event?.from.toString()}>
                      {EventDateDisplay(
                        meta.event?.from.toString(),
                        meta.event?.to.toString()
                      )}
                    </Typography>
                  )}
                </div>
              </div>
            )}
          </div>
        </ConditionalWrapper>
        <div className="flex flex-col gap-y-1">
          {heading && (
            <ConditionalWrapper
              condition={!!link}
              wrapper={(children) => (
                <Link
                  href={link}
                  className="hover:underline"
                  target={link.includes('http') ? '_blank' : '_self'}>
                  {children}
                </Link>
              )}>
              <Typography tag="p" size="text-xl" weight="font-semibold">
                {heading.replace(/[^\x00-\x7F]/g, '')}
              </Typography>
            </ConditionalWrapper>
          )}
          {summary && (
            <Typography
              tag="p"
              size="text-base"
              className="mb-2 line-clamp-2 text-gray-700">
              {summary.json ? documentToPlainTextString(summary.json) : summary}
            </Typography>
          )}
          <div className="space-y-2">
            {meta.location && (
              <div className="flex items-center space-x-2 text-sm text-gray-700">
                <div className="icon-pin" />
                <Typography tag="p">{meta.location}</Typography>
              </div>
            )}

            {meta.operator && (
              <div className="flex items-start space-x-2 text-sm text-gray-700">
                <div className="icon-building" />
                <Typography tag="p">{meta.operator}</Typography>
              </div>
            )}
          </div>
        </div>
      </div>
      {buttons &&
        buttons.map((item, index) => (
          <Button
            newWindow={link.includes('http') ? true : false}
            key={`button-${index}`}
            {...item}
          />
        ))}
    </div>
  );
};

export default CardDefault;
