import { Typography } from '@components/Typography';
import { useClearRefinements } from 'react-instantsearch';

export default function ResetFilters(props) {
  const { refine, canRefine } = useClearRefinements(props);

  return (
    <div
      onClick={() => {
        refine();
      }}>
      <Typography
        tag="p"
        className="m-0 w-full cursor-pointer text-base hover:underline">
        Reset filters
      </Typography>
    </div>
  );
}
