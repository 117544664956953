'use client';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { userStore } from 'store/UserStore';

export interface FavouriteProps {
  productID: any;
  style: 'card' | 'badge';
  showCount?: boolean;
  className?: string;
}

export const Favourite = React.memo<FavouriteProps>(
  ({ productID, style = 'card', className }) => {
    const [isFavourite, setIsFavourite] = useState<boolean>(false);
    const { favourites, setFavourite, removeFavourite } = userStore();

    useEffect(() => {
      setIsFavourite(favourites.includes(productID));
    }, [favourites, productID]);

    const toggleFavourite = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (isFavourite) {
          removeFavourite(productID);
        } else {
          setFavourite(productID);
        }
        setIsFavourite(!isFavourite);
      },
      [isFavourite, productID, setFavourite, removeFavourite]
    );

    return (
      <button
        className={clsx(
          'group flex cursor-pointer items-center space-x-1 transition-all ease-out',
          {
            'favourite-badge hover:bg-brand-mothernature-pressed rounded-lg border bg-white  p-2 text-brand-mothernature hover:border-brand-wintersunset hover:bg-brand-wintersunrise/20 hover:shadow-sm':
              style === 'badge',
            'transform-gpu delay-150 duration-1000': style === 'card',
          },
          className
        )}
        onClick={toggleFavourite}
        aria-label="Toggle Favourite">
        <span
          className={clsx(
            'heart nice-transition size-5',
            isFavourite ? 'hearted hover:opacity-100' : 'hover:opacity-80'
          )}
          role="presentation"
        />
        {style === 'badge' && (
          <span className="hidden text-sm font-semibold md:block">
            {isFavourite ? 'Saved' : 'Wishlist'}
          </span>
        )}
      </button>
    );
  }
);

export default Favourite;
