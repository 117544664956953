import clsx from 'clsx';
import { ReactComponent as StarIcon } from '/public/icons/tripadvisor/star.svg';
import { ReactComponent as TripAdvisorIcon } from '/public/icons/tripadvisor/tripadvisor.svg';

export interface StarRatingProps {
  score: any;
  numberReviews?: string;
  type?: 'badge' | 'inline';
  className?: string;
}

export const TripAdvisorStars: React.FC<{ score: number }> = ({ score }) => {
  const validScore = Math.max(0, Math.min(5, score));
  const halfStar = score % 1 >= 0.5 ? 1 : null;

  const stars = Array.from({ length: validScore }, (_, index) => (
    <StarIcon key={index} className="size-4" />
  ));

  return (
    <>
      {stars}
      {halfStar && <StarIcon className="size-4 opacity-50" />}
    </>
  );
};

const TripAdvisorScore: React.FC<StarRatingProps> = ({
  score,
  numberReviews,
  type = 'badge',
  className,
}) => {
  return (
    <span
      className={clsx(
        'inline-flex items-center justify-start space-x-2 text-sm font-semibold',
        type === 'badge' &&
          'rounded-md border border-gray-300 p-2 group-hover/link:border-brand-mothernature group-hover/link:bg-brand-mothernature group-hover/link:text-white',
        className
      )}
      itemProp="aggregateRating"
      itemScope
      itemType="http://schema.org/AggregateRating">
      <TripAdvisorIcon className="size-5" />
      <span className="flex items-center">
        {type === 'badge' && (
          <span className="mr-2">{`${numberReviews} reviews`}</span>
        )}
        <TripAdvisorStars score={score} />
        <span className="sr-only" itemProp="ratingValue">
          {score}
        </span>
      </span>
    </span>
  );
};

export default TripAdvisorScore;
