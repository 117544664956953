export const accessibilityTitles = (title: string): string => {
  if (!title) {
    return '';
  }

  const titlesMap: Record<string, string> = {
    ALERGYINTOLRNCE: 'Allergies and intolerances',
    AMBULANT: 'Walking frames or mobility aids',
    COMMUNICATION: 'Assists people who have challenges with communication',
    HEARIMPAIR: 'Deaf or hearing loss',
    VISIONIMPAIR: 'Assists people who are blind or have vision loss',
    WHEELCHAIR: 'Wheelchair access',
  };

  const normalizedTitle = title.toUpperCase();
  const mappedTitle = titlesMap[normalizedTitle];

  // Return the mapped title if found; otherwise, return the title in lowercase
  return mappedTitle || title.toLowerCase();
};

export default accessibilityTitles;
