export const AttributesToRetrieve = [
  'productName',
  'dealName',
  'productDescription',
  'productCategoryId',
  'dealMultimedia',
  'productImage',
  'startDateTimestamp',
  'endDateTimestamp',
  'frequencyStartDate',
  'frequencyEndDate',
  'dealRedeemStartDate',
  'dealRedeemEndDate',
  'title',
  'contentType',
  'rates.priceFrom',
  'rates.priceTo',
  'slug',
  'summary',
  'hero',
  'publishedDate',
  'places',
  'addresses',
  'accessibilityAttributes',
  'parentProductCategoryId',
  'parentProductId',
  'parentProductName',
  'hasDeal',
  'tripAdvisorDetails',
  'verticalClassifications',
  'name',
];

export const HideFilters =
  'NOT status:EXPIRED AND NOT productCategoryId:JOURNEY AND NOT owningOrganisationId:63b550451a4c9898053b790d';
