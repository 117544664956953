'use client';
import 'flatpickr/dist/themes/airbnb.css';

import { Typography } from '@components/Typography';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { UseRangeProps, useInstantSearch, useRange } from 'react-instantsearch';

export interface DateRangeProps extends UseRangeProps {
  enabled?: string;
  className?: string;
}

const Flatpickr = dynamic<any>(() => import('react-flatpickr'));

export default function DateRange<DateRangeProps>(props) {
  const { range, canRefine, refine } = useRange(props);
  const { enabled, className } = props;
  const { indexUiState } = useInstantSearch();

  if (
    enabled &&
    !indexUiState?.refinementList?.productCategoryId?.includes(
      enabled.toUpperCase()
    )
  ) {
    return null;
  }

  if (!canRefine) {
    return null;
  }

  return (
    <Flatpickr
      options={{
        mode: 'range',
        dateFormat: 'd/m/y',
        minDate: 'today',
      }}
      onClose={(dates) => {
        dates.length &&
          refine([
            dates[0].getTime() <= range.min ? range.min : dates[0].getTime(),
            dates[1].getTime() + 86400000,
          ]);
      }}
      render={({ defaultValue, value, ...props }, ref) => {
        return (
          <div className={clsx('space-y-4 rounded', className)}>
            <div>
              <div>
                <Typography
                  tag="span"
                  weight="font-semibold"
                  size="text-lg"
                  className="pt-4">
                  Event Date
                </Typography>
              </div>
              <input
                {...props}
                defaultValue={defaultValue}
                ref={ref}
                placeholder="Search dates"
                className="mt-2 w-full rounded-md border border-gray-600 px-4 py-2 focus:border-blue-300 focus:outline-none focus:ring-2 sm:text-sm"
              />
            </div>
          </div>
        );
      }}
    />
  );
}
