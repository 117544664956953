import { StringToURL } from './StringToURL';

interface DealInfo {
  parentProductId: string;
  productCategoryId: string;
  parentProductName: string;
}

export const ATDWToURL = (
  productCategoryId: string,
  objectID: string,
  productName: string,
  queryString?: string,
  deal?: DealInfo
): string | undefined => {
  if (!productCategoryId || !objectID || !productName) {
    return undefined;
  }

  const categoryMap: Record<string, string> = {
    ACCOMM: 'accommodation',
    // Add more mappings as needed
  };

  // Helper function to get clean category ID
  const getCleanCategoryID = (categoryId: string): string =>
    categoryMap[categoryId.toUpperCase()] || categoryId.toLowerCase();

  // Construct query string
  const cleanQueryString = queryString ? `?${queryString}` : '';

  // Construct URL for deals with parent product information
  if (deal && productCategoryId.toUpperCase() === 'DEAL') {
    const { parentProductId, productCategoryId, parentProductName } = deal;
    if (parentProductId && productCategoryId && parentProductName) {
      return `/${getCleanCategoryID(productCategoryId)}/${parentProductId}/${StringToURL(parentProductName)}/#deals`;
    }
  }

  // Construct URL for regular products or deals without parent information
  return `/${getCleanCategoryID(productCategoryId)}/${objectID}/${StringToURL(productName)}${cleanQueryString}`;
};

export default ATDWToURL;
