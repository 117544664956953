import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import Link from 'next/link';
import { TripAdvisor } from 'types/vsc_atdwapi';
import { ButtonProps } from '../Button';
import { ConditionalWrapper } from '../ConditionalWrapper';
import { ImageProps } from '../Image';
import { Typography } from '../Typography';
import CardBasic from './Card.Basic';
import CardDefault from './Card.Default';
import CardImage from './Card.Image';

export interface CardProps {
  // Core props
  type?: 'card' | 'deal' | 'basic' | 'backgroundimage';

  image?: ImageProps;
  heading: string;
  summary?: string | any;

  // Meta
  meta?: {
    type?: string;
    event?: {
      from: string;
      to: string;
    };
    location?: string;
    operator?: string;
    hasDeal?: boolean;
    score?: TripAdvisor;
  };

  // Extra detail
  favourite?: {
    productId?: string;
  };
  buttons?: [ButtonProps];
  link?: string;

  // Styling
  className?: string;

  // Image Priority
  priority?: boolean;
}

export type CardImageProps = Pick<CardProps, 'image' | 'link'>;

const CardVariants = cva(
  'nice-transition not-prose group relative flex flex-col justify-between h-full w-full',
  {
    variants: {
      type: {
        card: 'bg-white border border-gray-300 hover:border-brand-mothernature/50 p-4 shadow-lg rounded-xl ',
        deal: 'bg-white border border-brand-wintersunrise hover:border-brand-wintersunset hover:bg-brand-wintersunrise/5 p-4 shadow-lg rounded-xl ',
        basic: '',
        backgroundimage: '',
      },
    },

    defaultVariants: {
      type: 'card',
    },
  }
);

export const DealFlag: React.FC = () => {
  return (
    <span className="flex space-x-1">
      <Typography
        tag="span"
        size="text-sm"
        weight="font-semibold"
        className="capitalize text-brand-wintersunset">
        Deal
      </Typography>
      <span className="icon-deal" role="presentation" />
    </span>
  );
};

export const Card: React.FC<CardProps> = ({
  type = 'card',
  image,
  heading,
  summary,
  meta,
  favourite,
  buttons,
  link,
  className,
  priority,
}) => {
  return (
    <ConditionalWrapper
      condition={link !== undefined && !buttons}
      wrapper={(children) => (
        <Link
          href={link}
          className="block size-full"
          target={link.includes('http') ? '_blank' : '_self'}>
          {children}
        </Link>
      )}>
      <div className={clsx(CardVariants({ type, className }))}>
        {(type === 'card' || type === 'deal') && (
          <CardDefault
            type={type}
            image={image}
            heading={heading}
            summary={summary}
            meta={meta}
            favourite={favourite}
            buttons={buttons}
            link={link}
            className={className}
            priority={priority}
          />
        )}
        {type === 'basic' && (
          <CardBasic
            image={image}
            heading={heading}
            link={link}
            className={className}
            priority={priority}
          />
        )}
        {type === 'backgroundimage' && (
          <CardImage
            //type="Place"
            link={link}
            heading={heading}
            summary={summary}
            image={image}
            priority={priority}
          />
        )}
      </div>
    </ConditionalWrapper>
  );
};

export default Card;
