import { TitlesMap } from '@helpers/ATDWMappers';

export const prettyTitles = (title: string, plural = false): string => {
  if (!title) {
    return '';
  }

  const key = title.toUpperCase();
  const matchedTitle = TitlesMap[key];

  if (matchedTitle) {
    return plural ? matchedTitle.plural : matchedTitle.singular;
  }

  // Fallback for any title not found in the map, ensuring the first letter is capitalized
  return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
};

export default prettyTitles;
