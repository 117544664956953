import React from 'react';

export const EventDateDisplay = (startDate: string, endDate: string) => {
  // Break out if there are no dates for this item
  if (!startDate && !endDate) {
    return;
  }

  if (startDate === endDate || !endDate) {
    return new Date(startDate).toDateString();
  } else {
    return `${new Date(startDate).toDateString()} - ${new Date(
      endDate
    ).toDateString()}`;
  }
};
