interface Beach {
  name: string;
}
type Beaches = {
  [key: string]: Beach;
};

export const placesToUrlMap = {
  AMAMOOR: { name: 'gympie' },
  BALDKNOB: { name: 'the-hinterland' },
  BALMORALRIDGE: { name: 'the-hinterland' },
  BARINGA: { name: 'caloundra' },
  BATTERYHILL: { name: 'caloundra' },
  BELLSCREEK: { name: 'caloundra' },
  BELLIPARK: { name: 'eumundi' },
  BEERBURRUM: { name: 'the-hinterland' },
  BIRTINYA: { name: 'kawana' },
  BLACKMOUNTAIN: { name: 'the-hinterland' },
  BOKARINA: { name: 'kawana' },
  BOLLIER: { name: 'gympie' },
  BOREENPOINT: { name: 'noosa' },
  BUDDINA: { name: 'kawana' },
  BUDINA: { name: 'kawana' },
  BULCOCKBEACH: { name: 'caloundra' },
  CASTAWAYSBEACH: { name: 'noosa' },
  CALOUNDRAWEST: { name: 'caloundra' },
  CAMBROON: { name: 'the-hinterland' },
  CHEVALLUM: { name: 'nambour' },
  COOCHINCREEK: { name: 'caloundra' },
  COOLOOLA: { name: 'gympie' },
  COOLABINE: { name: 'the-hinterland' },
  COORAN: { name: 'noosa' },
  COOROIBAH: { name: 'noosa' },
  COOROY: { name: 'noosa' },
  COOROYMOUNTAIN: { name: 'noosa' },
  CROHAMHURST: { name: 'the-hinterland' },
  CURRIMUNDI: { name: 'kawana' },
  DIDDILLIBAH: { name: 'nambour' },
  DICKYBEACH: { name: 'caloundra' },
  DOONAN: { name: 'noosa' },
  EERWAHVALE: { name: 'eumundi' },
  EUDLO: { name: 'nambour' },
  FLAXTON: { name: 'the-hinterland' },
  FORESTGLEN: { name: 'maroochydore' },
  GLENVIEW: { name: 'the-hinterland' },
  GLASSHOUSEMOUNTAINS: { name: 'the-hinterland' },
  GOLDENBEACH: { name: 'caloundra' },
  GOOMERI: { name: 'gympie' },
  HUNCHY: { name: 'nambour' },
  ILKLEY: { name: 'nambour' },
  INSKIP: { name: 'gympie' },
  KAWANAWATERS: { name: 'kawana' },
  KIELSMOUNTAIN: { name: 'nambour' },
  KILKIVAN: { name: 'gympie' },
  KINKIN: { name: 'gympie' },
  KULANGOOR: { name: 'nambour' },
  KULUIN: { name: 'maroochydore' },
  KUNDAPARK: { name: 'maroochydore' },
  LANDERSSHOOT: { name: 'the-hinterland' },
  LANDSBOROUGH: { name: 'the-hinterland' },
  LITTLEMOUNTAIN: { name: 'caloundra' },
  MARCUSBEACH: { name: 'noosa' },
  MAROOCHYRIVER: { name: 'coolum-beach' },
  MERIDANPLAINS: { name: 'caloundra' },
  MINYAMA: { name: 'kawana' },
  MOFFATBEACH: { name: 'caloundra' },
  MONS: { name: 'maroochydore' },
  MOOLOOLAH: { name: 'the-hinterland' },
  MOOLOOLAHVALLEY: { name: 'the-hinterland' },
  MOOLORT: { name: 'the-hinterland' },
  MOUNTCOLUM: { name: 'coolum-beach' },
  MOUNTEERWAH: { name: 'eumundi' },
  MOUNTNINDERRY: { name: 'eumundi' },
  MOTHARMOUNTAIN: { name: 'gympie' },
  MOYPOCKET: { name: 'the-hinterland' },
  MUDJIMBA: { name: 'coolum-beach' },
  NINDERRY: { name: 'coolum-beach' },
  NOOSAHEADS: { name: 'noosa' },
  NOOSAVILLE: { name: 'noosa' },
  NORTHARM: { name: 'eumundi' },
  PALMVIEW: { name: 'maroochydore' },
  PARKLANDS: { name: 'nambour' },
  PARREARRA: { name: 'kawana' },
  PEACHESTER: { name: 'the-hinterland' },
  PEREGIANSPRINGS: { name: 'noosa' },
  PELICANWATERS: { name: 'caloundra' },
  PINBARREN: { name: 'gympie' },
  POINTARKWRIGHT: { name: 'coolum-beach' },
  POINTCARTWRIGHT: { name: 'kawana' },
  REESVILLE: { name: 'the-hinterland' },
  ROSEMOUNT: { name: 'nambour' },
  SIPPYDOWNS: { name: 'maroochydore' },
  SUNRISEBEACH: { name: 'noosa' },
  SUNSHINEBEACH: { name: 'noosa' },
  TANAWHA: { name: 'maroochydore' },
  VALDORA: { name: 'coolum-beach' },
  VERRIERDALE: { name: 'noosa' },
  WARANA: { name: 'kawana' },
  WEYBADOWNS: { name: 'noosa' },
  WESTWOOMBYE: { name: 'nambour' },
  WITTA: { name: 'eumundi' },
  WOOMBYE: { name: 'nambour' },
  WURTULLA: { name: 'kawana' },
  YANDINACREEK: { name: 'coolum-beach' },
  YAROOMBA: { name: 'coolum-beach' },
};

export const TitlesMap: Record<string, { singular: string; plural: string }> = {
  ACCOMM: { singular: 'Accommodation', plural: 'Accommodation' },
  APARTMENT: { singular: 'Apartment', plural: 'Apartment' },
  ATTRACTION: { singular: 'Attraction', plural: 'Attractions' },
  BACKPACKER: {
    singular: 'Backpacker',
    plural: 'Backpackers',
  },
  BEDBREAKFA: {
    singular: 'Bed & Breakfast',
    plural: 'Bed & Breakfast',
  },
  CABCOTTAGE: { singular: 'Cottage', plural: 'Cottages' },
  COUPLES: { singular: 'My Friends', plural: 'My Friends' },
  DEAL: { singular: 'Deal', plural: 'Deals' },
  DESTINFO: { singular: 'Place', plural: 'Places' },
  'ECO FRIENDLY': { singular: 'Sustainable', plural: 'Sustainable' },
  EVENT: { singular: 'Event', plural: 'Events' },
  EVTBUS: {
    singular: 'Business Event',
    plural: 'Business Events',
  },
  EVTCLASS: {
    singular: 'Classes, Lessons, Workshops and Talk',
    plural: 'Classes, Lessons, Workshops and Talks',
  },
  EVTCOMNTY: {
    singular: 'Community Event',
    plural: 'Community Events',
  },
  EVTFOOD: {
    singular: 'Food & Wine',
    plural: 'Food & Wine',
  },
  EVTMARKET: {
    singular: 'Market',
    plural: 'Markets',
  },
  EXTERNALLINK: { singular: 'Link', plural: 'Links' },
  EXHIBIT: {
    singular: 'Exhibition and Show',
    plural: 'Exhibition and Shows',
  },
  FAMILIES: { singular: 'My Family', plural: 'My Family' },
  FARMSTAY: {
    singular: 'Farmstay',
    plural: 'Farmstays',
  },
  FESTIVAL: { singular: 'Festival', plural: 'Festivals' },
  GENSERVICE: { singular: 'General Services', plural: 'General Services' },
  GROUP: { singular: 'A Group', plural: 'Groups' },
  GUIDE: { singular: 'Guide', plural: 'Guides' },
  HOLHOUSE: { singular: 'Holiday House', plural: 'Holiday Houses' },
  HOTEL: { singular: 'Hotel', plural: 'Hotels' },
  JOURNEY: { singular: 'Journey', plural: 'Journeys' },
  MOTEL: { singular: 'Motel', plural: 'Motels' },
  NATURE: { singular: 'Nature & Wildlife', plural: 'Nature & Wildlife' },
  PAGE: { singular: 'Things To Do', plural: 'Things To Do' },
  PERFORMANC: {
    singular: 'Concert or Performance',
    plural: 'Concert or Performances',
  },
  PLACE: { singular: 'Place', plural: 'Places' },
  RESTAURANT: { singular: 'Food and Drink', plural: 'Food and Drink' },
  RESORT: { singular: 'Resort', plural: 'Resorts' },
  RETREAT: { singular: 'Retreat', plural: 'Retreats' },
  SOLO: { singular: 'Just by myself', plural: 'Just by myself' },
  SPORT: { singular: 'Sporting Event', plural: 'Sporting Events' },
  TOUR: { singular: 'Tour', plural: 'Tours' },
  TRANSPORT: { singular: 'Transport', plural: 'Transportation' },
  VANCAMP: {
    singular: 'Caravan, Camping & Holiday Park',
    plural: 'Caravan, Camping & Holiday Parks',
  },
};

// Inverse of the TitlesMap, find a category by the nicer title. Used in Dynamic Row where the category is set in Contentful
export const GetCategoryByTitle = (
  singularForm: string
): string | undefined => {
  singularForm = singularForm.trim();
  for (const key in TitlesMap) {
    if (TitlesMap[key].singular.trim() === singularForm) {
      return key;
    }
  }
  return undefined; // Return undefined if no key is found
};

/**
 * Retrieves all matching keys from the map based on the landingPage and ensures no duplicates.
 *
 * @param {string} landingPage - The landing page to match keys for.
 * @returns {string[]} An array containing all matching keys and the original landingPage with no duplicates.
 */
export const LandingPageToPlace = (landingPage: string): string[] => {
  landingPage = landingPage.trim();

  // Get all matching keys from the map and include the landingPage itself
  const matchingKeys = Object.keys(placesToUrlMap).filter(
    (key) => placesToUrlMap[key].name === landingPage
  );

  // Return the array with the original landingPage included, ensuring no duplicates
  return Array.from(new Set([...matchingKeys, landingPage]));
};

/**
 * Converts a place name to a landing page URL based on a mapping.
 *
 * @param {string} place - The name of the place to convert.
 * @return {string} The landing page URL corresponding to the provided place name.
 */
export const PlaceToLandingPage = (place: string) => {
  if (!place) {
    return null;
  }

  const key = place.replace(/\s/g, '').toUpperCase();
  const matchedTitle = placesToUrlMap[key];

  if (matchedTitle) {
    return `/place/${matchedTitle.name}`;
  }

  // Fallback to base place page
  return `/place/${place.replace(/\s/g, '')}`;
};
