type Multimedia = {
  serverPath?: string
}
interface Props {
  product?: {
    productCategoryId?: string;
    multimedia?: Multimedia[];
    dealMultimedia?: Multimedia;
    productImage?: string;
  };
}

export const ATDWImage = ({ product }: Props): string | undefined => {
  if (!product || !product.productCategoryId) {
    return undefined;
  }

  const categoryId = product.productCategoryId.toUpperCase();

  // Handle specific logic for 'DEAL' category
  if (categoryId === 'DEAL') {
    return (
      product.dealMultimedia?.[0]?.serverPath ||
      product.multimedia?.[0]?.serverPath ||
      product.productImage || 
      //@ts-ignore - This is for old deal objects from Contentful
      product.multimedia?.image?.[0]?.serverPath
    );
  }

  // Common logic for 'ACCOMM' and 'EVENT' categories, with a fallback to 'productImage'
  if (categoryId === 'ACCOMM' || categoryId === 'EVENT') {
    return product.multimedia?.[0]?.serverPath || product.productImage;
  }

  // Default case for other categories
  return product.productImage;
};

export default ATDWImage;
